import logo from "./logo.svg";
import "./App.css";
import Header from "./Componets/Header";
import Footer from "./Componets/Footer";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Services from "./pages/Services";
import About from "./pages/About";
import Faq from "./pages/Faq";
import Princing from "./pages/Princing";
import Payment from "./pages/Payment";
import UserPrivacyPolicy from "./pages/UserPrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import TermsandConditions from "./pages/TermsandConditions";
import Warning from "./pages/Warning";
import AmlPolicy from "./pages/AmlPolicy";
import Disclaimer from "./pages/Disclaimer";
import Landing from "./pages/Landing";
import RedressalPolicy from "./pages/RedressalPolicy";
import InvestorCharter from "./pages/InvestorCharter";
function App() {
  return (
    <>
      <div className="page-wrapper">
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/About" element={<About />} />
            <Route path="/Faq" element={<Faq />} />
            <Route path="/pricing" element={<Princing />} />
            <Route path="/payment" element={<Payment />} />
            <Route
              path="/User-Privacy-Policy"
              element={<UserPrivacyPolicy />}
            />
            <Route path="/User-Refund-Policy" element={<RefundPolicy />} />
            <Route path="/redressal-policy" element={<RedressalPolicy />} />
            <Route path="/investor-charter" element={<InvestorCharter />} />
            <Route
              path="/User-Terms-and-Conditions"
              element={<TermsandConditions />}
            />
            <Route path="/User-Warning" element={<Warning />} />
            <Route path="/User-AmlPolicy" element={<AmlPolicy />} />
            <Route path="/User-Disclaimer" element={<Disclaimer />} />
            <Route path="/landing" element={<Landing />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
