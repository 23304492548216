import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1200,
});

export default function RedressalPolicy() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="page-title">
        <div
          className="image-layer"
          style={{ backgroundImage: "url(assets/images/background/7.jpg)" }}
        />
        <div className="auto-container" data-aos="zoom-in">
          <h1>Redressal Policy</h1>
          <div className="bread-crumb">
            <ul className="clearfix" data-aos="zoom-in">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active" data-aos="zoom-in">
                Redressal Policy
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="about-two hire mx-2">
        <div className="outer-container clearfix">
          <div className="image-column">
            <div className="image-layer"></div>
          </div>
        </div>
      </section>

      <div className="container mb-3">
        <h1 className="fs-1" style={{ fontWeight: 500 }}>
          Client Grievance Redressal
        </h1>
        <div>
          <p>
            Client’s queries/complaints may arise due to a lack of understanding or a deficiency of service experienced by clients. Deficiency of service may include lack of explanation, clarifications, or understanding, which escalates into shortfalls in the expected delivery standards, either due to inadequacy of facilities available or through the attitude of staff towards the client.
          </p>
          <p>
            At Assure Market Research, we believe in providing truly personalized service to all our clients. During the course of our relationship, if you have experienced any inconvenience, we encourage you to let us know and give us the opportunity to resolve your concern. We assure you that we will address your complaint quickly and efficiently. Your complaint will also help us improve the quality of our products and services.
          </p>
          <p>
            After exhausting the above options for resolution of the grievance, if the investor/client is still not satisfied with the outcome, they can initiate dispute resolution through the ODR Portal: <a href="https://smartodr.in" target="_blank" rel="noopener noreferrer">smartodr.in</a>.
            For more details about the ODR mechanism, fees, timelines, etc., you may read the master circular released by SEBI titled: “Online Resolution of Disputes in the Indian Securities Market” available at the following link: <a href="https://www.sebi.gov.in/legal/master-circulars/aug-2023/online-resolution-of-disputes-in-the-indian-securities-market_75220.html" target="_blank" rel="noopener noreferrer">SEBI Circular</a>.
          </p>

          <h2>Grievance Redressal Levels</h2>

          <h3>1st Level</h3>
          <p>
            Clients can raise their concern or query directly to their respective executive, and if clients are not satisfied with the resolution provided, they can drop a detailed mail at <a href="mailto:support@assuremarketresearch.in">support@assuremarketresearch.in</a>. The turnaround time (TAT) for reverting back on issues received at support@assuremarketresearch.in will be 72 working hours.
          </p>

          <h3>2nd Level</h3>
          <p>
            If the client is not satisfied with the resolution provided on the 1st Level, they can escalate the complaint further to our compliance officer, Mr. Ritesh Gupta, by mailing <a href="mailto:support@assuremarketresearch.in">support@assuremarketresearch.in</a>. He is the highest authority at Assure Market Research and can redress the complaint in the best possible manner. The turnaround time (TAT) for reverting back on issues received at support@assuremarketresearch.in will be 10 working days, followed by a concrete investigation of the issue. Any complaint sent directly on the 2nd level will go unaddressed without any intimation.
          </p>

          <h3>3rd Level</h3>
          <p>
            If the client is still not satisfied with the resolution offered on Level 1 & 2, they can escalate the matter with the regulators at <a href="https://www.sebi.gov.in" target="_blank" rel="noopener noreferrer">www.sebi.gov.in</a>, keeping Assure Market Research in the loop. The above grievance mechanism has been provided to ensure better client satisfaction and bridge the gap between clients & Assure Market Research. Clients are requested to follow the above mechanism and hierarchy systems for smooth handling of issues and timely resolution. Clients are also requested to keep Assure Market Research in the loop in each of their emails, or else their issues will go unaddressed.
          </p>

          <h3>4th Level</h3>
          <p>
            In case you are not satisfied with our response, you can lodge your grievance with SEBI at <a href="https://scores.gov.in" target="_blank" rel="noopener noreferrer">SCORES</a> or you may also write to any of the offices of SEBI. SCORES may be accessed through the SCORES mobile application as well, which can be downloaded from the provided link.
          </p>

          <p>
            For more information, visit our website at <a href="https://assuremarketresearch.com/" target="_blank" rel="noopener noreferrer">www.assuremarketresearch.in</a>.
          </p>
        </div>
      </div>
    </>
  );
}
