import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import ComplaintDataPage from "../Componets/ComplaintDataPage";
AOS.init({
  duration: 1200,
});

export default function Home() {
  const phoneNumber = "+91 9977323535";
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/*Main Slider*/}
      <section className="main-slider">
        <div className="slider-bg">
          <div class="tp-caption tp-resizeme" data-aos="fade-right">
            <h2 className="colorprimary ">ASSURE MARKET RESEARCH</h2>
          </div>
          <div class="text tp-caption tp-resizeme" data-aos="fade-right">
            <h2>TRANSFORMING THE INDIAN STOCK MARKET FOR THE BETTER </h2>
          </div>
          <div class="text-center" data-aos="fade-right">
            <Link
              to={`tel:${phoneNumber}`}
              className="theme-btn btn-style-three"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </section>
      {/*End Main Slider*/}
      {/*About Section*/}
     
      <section class="about-two hire">
        <div class="outer-container clearfix" data-aos="zoom-in">
          <div class="image-column">
            <div class="image-layer"></div>
          </div>

          <div class="content-column">
            <div class="clearfix">
              <div class="inner" data-aos="fade-right">
                <div class="sec-title-two">
                  <h1>Who We Are</h1>
                </div>

                <h1>India’s </h1>
                <h1> SEBI Registered Research Analyst</h1>

                <div class="text" data-aos="fade-right">
                  <p>
                    Assure Research Analyst stands as a premier SEBI Registered
                    Research Analyst (SEBI Registration Number – INH000011802)
                    in India, spearheading the next generation of equity
                    research analysis and innovative research recommendation
                    services. Our commitment to excellence is underscored by
                    unparalleled features and distinctive offerings. With a
                    remarkable track record of client retention, we set a
                    benchmark in the industry.
                  </p>
                </div>
                <div class="link-box" data-aos="fade-right">
                  <Link
                    to={`tel:${phoneNumber}`}
                    className="theme-btn btn-style-two"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ComplaintDataPage />
      {/*Services Section*/}
      <section className="services-section">
        <div className="auto-container">
          {/*Heading*/}
          <div className="sec-title centered" data-aos="zoom-in">
            <h1>Our Fundamental Principles</h1>
          </div>
          <div className="row clearfix">
            {/*Service Block*/}
            <div
              className="service-block-one col-lg-4 col-md-4 col-sm-6 col-xs-12 "
              data-aos="zoom-in"
            >
              <div className="inner-box services-card ">
                <div className="icon-box">
                  <span className="flaticon-idea-1" />
                </div>
                <h3 className="text-center">
                  OUR CUSTOMERS MEAN EVERYTHING TO US
                </h3>
                <div className="text text-justifly">
                  At Assure Market Research, our unwavering commitment is to
                  deliver precisely what our customers seek.Our paramount
                  objective is to ensure customer satisfaction through both
                  exceptional results and continuous, By doing so, we aim to
                  eliminate any obstacles that may be hindering them from
                  participating in the stock market
                </div>
              </div>
            </div>
            {/*Service Block*/}
            <div
              className="service-block-one col-lg-4 col-md-4 col-sm-6 col-xs-12 "
              data-aos="zoom-in"
            >
              <div className="inner-box services-card ">
                <div className="icon-box">
                  <span className="flaticon-piggy-bank" />
                </div>
                <h3>GROW AND ADJUST</h3>
                <div className="text">
                  Our team comprises of intelligent individuals who excel in
                  navigating the complexities of trading and offering adept
                  support. Through learning from past challenges, our team has
                  evolved into a group of seasoned, proficient, and extensively
                  trained experts capable of adeptly addressing all aspects of
                  our services.
                </div>
              </div>
            </div>
            {/*Service Block*/}
            <div
              className="service-block-one col-lg-4 col-md-4 col-sm-6 col-xs-12 "
              data-aos="zoom-in"
            >
              <div className="inner-box services-card ">
                <div className="icon-box">
                  <span className="flaticon-skyline" />
                </div>
                <h3>CONSTRUCT FOR LONGEVITY</h3>
                <div className="text">
                  We aspire to craft something of true value, and thus our
                  research team consistently endeavors to explore novel avenues
                  and devise optimal trading strategies. Our approach revolves
                  around an unwavering dedication to our customers'
                  achievements, the cultivation of superior methodologies.
                </div>
              </div>
            </div>

            {/*Service Block*/}
            <div
              className="service-block-one col-lg-4 col-md-4 col-sm-6 col-xs-12 "
              data-aos="zoom-in"
            >
              <div className="inner-box services-card ">
                <div className="icon-box">
                  <span className="flaticon-wedding-planning" />
                </div>
                <h3>TRANSPARENCY</h3>
                <div className="text">
                  We take pride in offering comprehensive transparency into both
                  our triumphs and occasional setbacks as we expand and elevate
                  our business. This practice is woven into the fabric of our
                  organizational culture, as we collaboratively strive towards a
                  shared objective and vision of consistent improvement.
                </div>
              </div>
            </div>
            {/*Service Block*/}
            <div
              className="service-block-one col-lg-4 col-md-4 col-sm-6 col-xs-12 "
              data-aos="zoom-in"
            >
              <div className="inner-box services-card ">
                <div className="icon-box">
                  <span className="flaticon-dollar-sign-in-a-gear-wheel-outline" />
                </div>
                <h3>MAKE A DIFFERENCE EVERY DAY</h3>
                <div className="text">
                  We continuously challenge ourselves to reach our utmost
                  potential, directing our attention towards solutions, and each
                  day, we approach our tasks with a sense of inspiration, aiming
                  to create a meaningful influence through our skills, fervor,
                  and diligent efforts. While each of us is exceptional in our
                  own right, it is through collaborative teamwork that we attain
                  true excellence.
                </div>
              </div>
            </div>
            {/*Service Block*/}
            <div
              className="service-block-one col-lg-4 col-md-4 col-sm-6 col-xs-12  "
              data-aos="zoom-in"
            >
              <div className="inner-box services-card ">
                <div className="icon-box">
                  <span className="flaticon-user-1" />
                </div>
                <h3>
                  <h3>PROPRIETARY RESEARCH AND IN-HOUSE SERVICES</h3>
                </h3>
                <div className="text">
                  All our research endeavors and services are meticulously
                  crafted in-house. We refrain from outsourcing any aspect of
                  our operations. A dedicated team is entrusted with delivering
                  exceptional services to our clients, driven by our overarching
                  vision of fostering enduring relationships with our esteemed
                  clientele.
                </div>
              </div>
            </div>
            {/*Service Block*/}
          </div>
        </div>
      </section>

      {/* section three */}

      <section class="why-we" data-aos="flip-up">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="text-column pull-right col-md-6 col-sm-12 col-xs-12">
              <div class="inner" data-aos="zoom-in">
                <h2>
                  Promoting Ethical Business Practices for Sustainable Profits
                </h2>
                <div class="text" data-aos="zoom-in">
                  Assure Market Research stands as a trailblazing research firm,
                  boasting a team of specialized financial market analysts with
                  extensive experience in conducting comprehensive capital
                  market research. Our offerings encompass dependable tips and
                  advice catering to Intraday Trading, swing trading, and
                  investment strategies across various segments, including
                  Equities, Derivatives, Commodities, and Forex. We cover a wide
                  spectrum of trading options such as NIFTY, Bank Nifty, Stock
                  Option, futures and options (F&O) traded on the NSE and BSE,
                  as well as Commodities like bullion and metals traded on the
                  MCX and NCDEX.
                </div>
                <div class="text" data-aos="zoom-in">
                  Our comprehensive array of daily and weekly reports provides a
                  holistic overview of both stock and commodity markets. This
                  empowers investors to discern market trends and make informed
                  decisions, ultimately facilitating astute choices.”
                </div>
              </div>
            </div>

            <div class="image-column col-md-6 col-sm-8 col-xs-12">
              <div class="inner">
                <figure class="image">
                  <img src="assets/images/resource/image-1.jpg" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* apooinment */}

      <section class="call-to-action">
        <div class="auto-container">
          <div class="clearfix" data-aos="zoom-in">
            <h2>Schedule Your Appointment Today</h2>
          </div>
          <div class="clearfix" data-aos="zoom-in">
            <h2>India’s Top-Notch SEBI Registered Research Analyst</h2>
          </div>
          <div class="clearfix" data-aos="fade-right">
            <Link to={`tel:${phoneNumber}`} class="theme-btn btn-style-four">
              Contact us
            </Link>
          </div>
        </div>
      </section>

      {/*Services Section*/}
      <section className="services-three">
        <div className="auto-container">
          {/*Heading*/}
          <div className="sec-title-two centered" data-aos="zoom-in">
            <h1>Our Approach</h1>
          </div>
          <div className="services-outer">
            {/*Service Block*/}
            <div className="service-block-outer clearfix" data-aos="fade-right">
              {/*Service Block*/}
              <div className="service-block-three">
                <div className="inner-box ">
                  <figure className="icon-box" data-aos="flip-right">
                    <img src="assets/images/icons/s-icon-4.png" alt="" />
                  </figure>
                  <h3>
                    <a href="/contact">REQUIREMENT</a>
                  </h3>
                  <div className="text">
                    We thoroughly comprehend your needs through effective
                    counseling sessions, enabling us to meticulously assess and
                    recommend the most fitting services tailored to your
                    specific requirements.
                  </div>
                </div>
              </div>
            </div>
            {/*Service Block*/}
            <div className="service-block-outer clearfix" data-aos="fade-left">
              {/*Service Block*/}
              <div className="service-block-three">
                <div className="inner-box ">
                  <figure className="icon-box" data-aos="flip-right">
                    <img src="assets/images/icons/s-icon-8.png" alt="" />
                  </figure>
                  <h3>
                    <a href="/contact">SELECTION</a>
                  </h3>
                  <div className="text">
                    Following counseling and a comprehensive grasp of your
                    needs, we propose the ideal service for your consideration.
                    Once you make your selection and enroll in the chosen
                    service, we proceed accordingly.
                  </div>
                </div>
              </div>
            </div>
            {/*Service Block*/}
            <div className="service-block-outer clearfix" data-aos="fade-right">
              {/*Service Block*/}
              <div className="service-block-three">
                <div className="inner-box ">
                  <figure className="icon-box" data-aos="flip-right">
                    <img src="assets/images/icons/s-icon-6.png" alt="" />
                  </figure>
                  <h3>
                    <a href="/contact">PLANNING</a>
                  </h3>
                  <div className="text">
                    Upon service selection, we formulate a strategic plan and
                    craft recommendations tailored specifically to your needs.
                  </div>
                </div>
              </div>
            </div>
            {/*Service Block*/}
            <div className="service-block-outer clearfix" data-aos="fade-left">
              {/*Service Block*/}
              <div className="service-block-three">
                <div className="inner-box ">
                  <figure className="icon-box" data-aos="flip-right">
                    <img src="assets/images/icons/s-icon-7.png" alt="" />
                  </figure>
                  <h3>
                    <a href="/contact">EXECUTION</a>
                  </h3>
                  <div className="text">
                    We deliver services in alignment with your risk profile and
                    available capital, with an unwavering focus on upholding
                    quality and safety as our foremost concerns. Our commitment
                    to quality is reflected in our approach of providing a
                    reduced number of trades.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*Why Us Section*/}
      <section
        className="why-us"
        style={{ backgroundImage: "url(assets/images/background/5.jpg)" }}
      >
        <div className="outer-container clearfix">
          {/*Content Column*/}
          <div className="content-column">
            <div className="inner">
              {/*Heading*/}
              <div className="sec-title-two light" data-aos="zoom-in">
                <h1>Why Choose Us?</h1>
              </div>
              <div className="text" data-aos="zoom-in">
                We’re with You Every Step of the Way: A single call connects you
                to our unwavering support.
              </div>
              <div className="text" data-aos="zoom-in">
                Tech-Powered & Timely: Real-time recommendations delivered via
                SMS & Email during market hours.
              </div>
              <div className="text" data-aos="zoom-in">
                Expertise and Proprietary Solutions: Over 50 years of collective
                experience and advanced algorithms committed to growing your
                wealth.
              </div>
              <div className="text" data-aos="zoom-in">
                Guidance Throughout Your Investment Journey: Count on our
                guidance from entry to exit levels, ensuring you’re never alone.
              </div>

              <div className="link-box" data-aos="fade-down">
                <Link to={`tel:${phoneNumber}`} class="theme-btn btn-style-two">
                  Get Start
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*News Section*/}
      <section className="news-section-two">
        <div className="auto-container">
          {/*Heading*/}
          <div className="sec-title-two centered" data-aos="zoom-in">
            <h1>OUR SERVICES</h1>
            <div class="clearfix" data-aos="zoom-in">
              <h3>
                We proudly stand as India’s premier Nifty and Bank Nifty Option
                Tips Specialist, heading a distinguished Research Analyst firm.
                Our foundation rests upon the trust of our valued customers and
                the precision of our recommendations, setting us apart from the
                rest.
              </h3>
            </div>
          </div>

          <div className="row clearfix gap-3">
            {/*News Block*/}
            <div
              className="news-block-two col-md-6  col-sm-6 col-xs-12"
              data-aos="fade-right"
            >
              <div className="inner-box ">
                <figure className="image-box">
                  <Link to="/">
                    <img src="assets/images/resource/image-13.jpg" alt="" />
                  </Link>
                </figure>
                <div className="lower-box">
                  {/* <div className="post-info">Post: 15 April 2018</div> */}
                  <h3 data-aos="zoom-in">
                    <Link to="/">Stock Market Intraday</Link>
                  </h3>
                  <div className="text" data-aos="zoom-in">
                    Our Day Trading calls are meticulously crafted
                    recommendations, derived from a comprehensive analysis of
                    technical and fundamental data.
                  </div>
                  <Link
                    to={`tel:${phoneNumber}`}
                    class="theme-btn btn-style-four"
                    data-aos="zoom-in"
                  >
                    Contact us
                  </Link>
                </div>
              </div>
            </div>
            {/*News Block*/}
            <div
              className="news-block-two col-md-6  col-sm-6 col-xs-12"
              data-aos="fade-left"
            >
              <div className="inner-box ">
                <figure className="image-box">
                  <Link to="/">
                    <img src="assets/images/resource/image-14.jpg" alt="" />
                  </Link>
                </figure>
                <div className="lower-box">
                  <h3>
                    <Link to="/" data-aos="zoom-in">
                      Bank Nifty Futures & Options
                    </Link>
                  </h3>
                  <div className="text" data-aos="zoom-in">
                    Leading the charts in advisory services for Bank Nifty
                    futures and options. Trade with minimal capital and unlock
                    substantial returns from the options market.
                  </div>
                  <Link
                    to={`tel:${phoneNumber}`}
                    class="theme-btn btn-style-four"
                    data-aos="zoom-in"
                  >
                    Contact us
                  </Link>
                </div>
              </div>
            </div>
            {/*News Block*/}
            <div
              className="news-block-two col-md-6  col-sm-6 col-xs-12"
              data-aos="fade-right"
            >
              <div className="inner-box ">
                <figure className="image-box">
                  <Link to="/">
                    <img src="assets/images/resource/image-3.jpg" alt="" />
                  </Link>
                </figure>
                <div className="lower-box">
                  {/* <div className="post-info">Post: 19 April 2018</div> */}
                  <h3>
                    <Link to="/" data-aos="zoom-in">
                      Stock Futures & Options
                    </Link>
                  </h3>
                  <div className="text" data-aos="zoom-in">
                    Empower your investments with accurate F&O tips. Futures and
                    options present a compelling avenue to amplify your wealth
                    effortlessly.
                  </div>
                  <Link
                    to={`tel:${phoneNumber}`}
                    class="theme-btn btn-style-four"
                    data-aos="zoom-in"
                  >
                    Contact us
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="news-block-two col-md-6  col-sm-6 col-xs-12"
              data-aos="fade-left"
            >
              <div className="inner-box ">
                <figure className="image-box">
                  <Link to="/">
                    <img src="assets/images/resource/image-2.jpg" alt="" />
                  </Link>
                </figure>
                <div className="lower-box">
                  {/* <div className="post-info">Post: 19 April 2018</div> */}
                  <h3>
                    <Link to="/" data-aos="zoom-in">
                      Nifty Futures & Options
                    </Link>
                  </h3>
                  <div className="text" data-aos="zoom-in">
                    Our future and option tips exhibit exceptional accuracy.
                    Nifty trading emerges as an ideal choice for attaining
                    short-term gains.
                  </div>
                  <Link
                    data-aos="zoom-in"
                    to={`tel:${phoneNumber}`}
                    class="theme-btn btn-style-four"
                  >
                    Contact us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*Statistics Section*/}
      <section className="statistics-section">
        <div className="outer-container clearfix" data-aos="flip-down">
          {/*Image Column*/}
          <div className="image-column">
            <div
              className="image-layer"
              style={{
                backgroundImage: "url(assets/images/background/2.jpg)",
              }}
            />
          </div>
          {/*Content Column*/}
          <div
            className="content-column"
            style={{
              backgroundImage: "url(assets/images/background/3.jpg)",
            }}
          >
            <div className="inner">
              {/*Heading*/}
              <div className="sec-title light" data-aos="zoom-in">
                <h1>Our Infrastructure</h1>
                <div className="text" data-aos="zoom-in">
                  This product is meant for educational purposes only.
                  Resemblance We boast a cutting-edge infrastructure, equipped
                  with the latest hardware and software, ensuring optimal
                  performance. Our dedicated research team operates during live
                  market hours, vigilantly monitoring market activities and
                  significant news events. This proactive approach not only
                  safeguards against substantial losses but also preserves
                  clients’ capital. Our advanced infrastructure empowers us to
                  deliver unparalleled services tailored to meet diverse
                  requirements.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
