import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1200,
});

export default function RefundPolicy() {
  const phoneNumber = "+91 9977323535";

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <section className="page-title">
        <div
          className="image-layer"
          style={{ backgroundImage: "url(assets/images/background/7.jpg)" }}
        />
        <div className="auto-container" data-aos="zoom-in">
          <h1>Refund Policy</h1>
          <div className="bread-crumb">
            <ul className="clearfix" data-aos="zoom-in">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active" data-aos="zoom-in">
                Refund Policy
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="about-two hire mx-2">
        <div className="outer-container clearfix">
          <div className="image-column">
            <div className="image-layer"></div>
          </div>
        </div>
      </section>

      <div className="container mb-3">
        <h1 className="fs-1" style={{ fontWeight: 500 }}>
          Refund Policy
        </h1>
        <div>
          <p>
            Assure Market Research values our clients and is committed to providing unsurpassed services. While we take our accuracy seriously, our clients also need to realize that we do not offer a 100% guarantee on our recommendations and hence cannot offer any refund on subscriptions regardless of the individual client’s performance.
          </p>
          <p>
            Please note that all sales are final. By accepting the proposal of service and making a payment for the subscription, you agree that there will be ABSOLUTELY NO REFUNDS and/or CANCELLATIONS IN ANY CIRCUMSTANCES.
          </p>
          <p>
            Once a service has been subscribed to and payment has been made for the same, the client will start receiving the recommendations they requested. If for some unforeseen reason, the client is not satisfied with our services, they may contact us to seek oversight on future recommendations.
          </p>
          <p>
            We at Assure Market Research will put our best effort to increase the satisfaction levels in such cases. However, any request by the client to cancel a service or get a refund will not be accepted in any case.
          </p>
          <p>
            In the unlikely event that after payment, service for a client is not started for technical reasons beyond the control of Assure Market Research and we decide not to start service for a specific client, the client will be paid back the amount as soon as it is possible. This will not be applicable if the client is not able to receive SMS on the number provided by the client because of the client’s DND status. If the records from our SMS service provider indicate that service SMS were sent to the number that the client provided, we will deem that as delivery of service from our end and will not entertain a request for a refund based on non-delivery of SMS. We advise our clients to deregister from DND either before making a payment or as soon as the service is started.
          </p>
          <h2>No Refund Policy</h2>
          <p>
            We strongly recommend that before making a payment, our visitors and potential clients, please:
          </p>
          <ul style={{ listStyleType: 'circle' }}>
            <li>Read all information about our products.</li>
            <li>Services and support are given to our clients.</li>
            <li>Visit the ‘About Us’ and ‘Past Performance’ sections on our website.</li>
            <li>Visit our Help and Frequently Asked Questions (FAQ) sections.</li>
            <li>Read our Terms of Use.</li>
            <li>Read our Privacy Policy.</li>
          </ul>
          <h2>Important</h2>
          <p>
            Assure Market Research takes the security of our client transactions very seriously. For this reason, we ask that you NOT allow children or other unauthorized family members or friends to access your credit cards, debit cards, or your account at the payment site to ensure that no one pays for a subscription without your permission. By making a payment for a subscription to our site, you acknowledge that you have read and agreed to the above Assure Market Research policies.
          </p>
        </div>
      </div>
    </>
  );
}
