import React from "react";

const ComplaintDataPage = () => {
  return (
    <div className="container mb-3">
      <h2>Complaint Data (As On Ending of August, 2024)</h2>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Sr. No</th>
            <th>Received from</th>
            <th>Pending at the end of last month</th>
            <th>Received</th>
            <th>Resolved</th>
            <th>Total Pending</th>
            <th>Pending Complaints &gt; 3 Months</th>
            <th>Average Resolution time (in days)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Directly from Investors</td>
            <td>0</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
            <td>0</td>
            <td>1</td>
          </tr>
          <tr>
            <td>2</td>
            <td>SEBI (SCORES)</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Other Sources (if any)</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td colSpan="2">Grand Total</td>
            <td>0</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
            <td>0</td>
            <td>1</td>
          </tr>
        </tbody>
      </table>

      <h2>Trend Of Monthly Disposal Of Complaints</h2>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Sr.No</th>
            <th>Month</th>
            <th>Carried forward from the previous month</th>
            <th>Received</th>
            <th>Resolved</th>
            <th>Pending</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>May</td>
            <td>0</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
          </tr>
          <tr>
            <td>2</td>
            <td>June</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>3</td>
            <td>July</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>4</td>
            <td>August</td>
            <td>0</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
          </tr>
          <tr>
            <td>5</td>
            <td>September</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td colSpan="2">Grand Total</td>
            <td>0</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
          </tr>
        </tbody>
      </table>

      <h2>Trend Of Year Disposal Of Complaints</h2>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Sr. No</th>
            <th>Year</th>
            <th>Carried forward from the previous Year</th>
            <th>Received</th>
            <th>Resolved</th>
            <th>Pending</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>2024-2025</td>
            <td>NA</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ComplaintDataPage;
