import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  duration: 1200,
});

export default function InvestorCharter() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="page-title">
        <div
          className="image-layer"
          style={{ backgroundImage: "url(assets/images/background/7.jpg)" }}
        />
        <div className="auto-container" data-aos="zoom-in">
          <h1>Investor Charter</h1>
          <div className="bread-crumb">
            <ul className="clearfix" data-aos="zoom-in">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active" data-aos="zoom-in">
                Investor Charter
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="about-two hire mx-2">
        <div className="outer-container clearfix">
          <div className="image-column">
            <div className="image-layer"></div>
          </div>
        </div>
      </section>

      <div className="container mb-5">
        <div className="investor-charter-page">
          <h2>Investor Charter</h2>

          <div className="section">
            <h3>Vision and Mission Statement for Investors</h3>
            <h4>Vision</h4>
            <p>
              Our Vision is to provide research services built on the principles
              of selecting stocks based on extensive screening, rigorous due
              diligence, and a focus on valuations to ensure optimal investment
              returns.
            </p>

            <h4>Mission</h4>
            <ul>
              <li>
                To enable investors to understand companies well and read the
                analysis before investing.
              </li>
              <li>To offer detailed analysis.</li>
              <li>To make them well-informed investors.</li>
            </ul>
          </div>

          <div className="section">
            <h3>Details of Business Transactions</h3>
            <p>
              Assure Market Research publishes research reports based on the
              research activities of our analysts to provide an independent and
              unbiased view on securities. We ensure transparency and objectivity
              while providing research recommendations.
            </p>
            <p>
              Our recommendations are based on publicly available information
              and known observations while disclosing financial interests in
              recommended securities.
            </p>
          </div>

          <div className="section">
            <h3>Details of Services Provided</h3>
            <p>
              The client onboarding process for Assure Market Research requires
              clients to submit their mobile number, email ID, and PAN details.
              We ensure that confidentiality is maintained until the research
              reports are made available in the public domain.
            </p>
          </div>

          <div className="section">
            <h3>Grievance Redressal Mechanism</h3>
            <p>
              In case of any grievance or complaint, investors should approach
              Assure Market Research and ensure that the grievance is resolved
              within 30 days. If not satisfied, complaints can be lodged with
              SEBI's SCORES portal for centralized complaints redressal.
            </p>
            <p>
              Physical complaints may also be sent to SEBI at their regional
              office in Ahmedabad, Gujarat.
            </p>
          </div>

          <div className="section">
            <h3>Expectations from Investors</h3>
            <h4>Do’s</h4>
            <ul>
              <li>Always deal with SEBI-registered Assure Market Research.</li>
              <li>Ensure that Assure Market Research has a valid registration certificate.</li>
              <li>Check for SEBI registration number and refer to the list of registered services on SEBI's website.</li>
              <li>Pay attention to disclosures made in the research reports before investing.</li>
              <li>
                Make payments to Assure Market Research only through banking
                channels and maintain duly signed receipts mentioning payment
                details.
              </li>
              <li>
                Before buying securities or applying in public offers, check the
                research recommendations provided by Assure Market Research.
              </li>
              <li>
                Ask all relevant questions and clear your doubts with Assure
                Market Research before acting on the recommendation.
              </li>
              <li>
                Inform SEBI about any assured or guaranteed returns offered by
                Assure Market Research.
              </li>
            </ul>

            <h4>Don’ts</h4>
            <ul style={{marginBottom:'2rem'}}>
              <li>Do not provide funds for investment directly to Assure Market Research.</li>
              <li>Don’t fall prey to enticing advertisements or market rumors.</li>
              <li>
                Do not get attracted to limited-period discounts or other
                incentives, gifts, etc., offered by Assure Market Research.
              </li>
              <li>Do not share login credentials and passwords of your trading and demat accounts.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
